exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-material-icon-js": () => import("./../../../src/pages/material/icon.js" /* webpackChunkName: "component---src-pages-material-icon-js" */),
  "component---src-pages-material-js": () => import("./../../../src/pages/material.js" /* webpackChunkName: "component---src-pages-material-js" */),
  "component---src-pages-material-tkool-mv-js": () => import("./../../../src/pages/material/tkool_mv.js" /* webpackChunkName: "component---src-pages-material-tkool-mv-js" */),
  "component---src-pages-material-wolf-rpg-editor-js": () => import("./../../../src/pages/material/wolf_rpg_editor.js" /* webpackChunkName: "component---src-pages-material-wolf-rpg-editor-js" */),
  "component---src-pages-material-wwa-js": () => import("./../../../src/pages/material/wwa.js" /* webpackChunkName: "component---src-pages-material-wwa-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-wwa-index-js": () => import("./../../../src/pages/wwa/index.js" /* webpackChunkName: "component---src-pages-wwa-index-js" */),
  "component---src-templates-default-tsx-content-file-path-src-contents-about-md": () => import("./../../../src/templates/default.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/about.md" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-contents-about-md" */),
  "component---src-templates-index-page-tsx-content-file-path-src-contents-index-md": () => import("./../../../src/templates/index-page.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/index.md" /* webpackChunkName: "component---src-templates-index-page-tsx-content-file-path-src-contents-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-a-train-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/a_train/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-a-train-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-aokashi-dot-net-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/aokashi_dot_net/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-aokashi-dot-net-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-aokashi-home-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/aokashi_home/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-aokashi-home-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-build-pc-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/build_pc.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-build-pc-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-cdl-rpg-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/cdl_rpg/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-cdl-rpg-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-cgi-town-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/cgi_town/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-cgi-town-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-coden-city-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/coden_city/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-coden-city-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-compaq-broken-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/compaq_broken/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-compaq-broken-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-first-internet-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/first_internet.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-first-internet-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-html-template-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/html_template/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-html-template-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-minecraft-server-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/minecraft_server/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-minecraft-server-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-notebook-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/notebook.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-notebook-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-portable-launcher-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/portable_launcher/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-portable-launcher-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-pso-2-team-site-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/pso2_team_site.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-pso-2-team-site-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-road-works-county-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/road_works_county/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-road-works-county-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-slack-community-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/slack_community.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-slack-community-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wide-wiki-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wide_wiki/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wide-wiki-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-word-dic-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/word_dic/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-word-dic-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-collection-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_collection/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-collection-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-game-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_game/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-game-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-maker-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_maker/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-maker-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-material-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_material/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-material-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-save-js-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_save_js/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-save-js-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-wing-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_wing/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-wing-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-wing-pe-index-mdx": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwa_wing_pe/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwa-wing-pe-index-mdx" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwabook-20th-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/wwabook_20th/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-wwabook-20th-index-md" */),
  "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-zeist-city-index-md": () => import("./../../../src/templates/portfolio-item.tsx?__contentFilePath=/home/aokashi/GitHub/aokashi_home/src/contents/portfolio/zeist_city/index.md" /* webpackChunkName: "component---src-templates-portfolio-item-tsx-content-file-path-src-contents-portfolio-zeist-city-index-md" */),
  "component---src-templates-portfolio-tag-tsx": () => import("./../../../src/templates/portfolio-tag.tsx" /* webpackChunkName: "component---src-templates-portfolio-tag-tsx" */)
}

